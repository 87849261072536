import React from "react";

const HomeReviewRow = () => {
	return (
		<div className="home-review-row d-flex flex-column justify-content-center align-items-center">
			<div className="container my-5 my-md-0">
				<div className="title">
					“Edlus.app uzstādīšanas process mūsu uzņēmumā bija ātrs un vienkāršs. Sistēmas ekspluatācijas divi gadi aizritējuši bez problēmām. Iesakām!”
				</div>
				<div className="subtitle">
					Antra Gudaņeca, <span>SIA AIMASA</span>
				</div>
			</div>
		</div>
	);
};

export default HomeReviewRow;
