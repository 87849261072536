import React, { useState } from "react";

const CostPanelItem = ({
	title = "Title",
	subtitle = "subtitle",
	amount = 0,
	image = null,
	onAdd = null,
	onReduce = null
}) => {
	return (
		<div className="cost-panel-item d-flex flex-column align-content-center align-items-start h-100">
			<div className="d-flex flex-column flex-md-row align-content-center align-items-start h-100">
				<div className={"d-flex align-items-center"}>
					<img
						className="align-self-center img-fluid d-none d-md-flex" 
						src={image}
						style={{ maxHeight: 100 }}
					/>
					<img
						className="align-self-center img-fluid d-flex d-md-none"
						src={image}
						style={{ maxHeight: 60 }}
					/>
				</div>
				<div className="d-flex flex-column">
					<div className="title">{title}</div>
					<div className="subtitle">{subtitle}</div>
				</div>
			</div>
			<div className="input d-flex flex-row align-content-between align-items-center px-1 px-md-3">
				<div onClick={onReduce}>
					<img className="align-self-center p-2" src={"images/minus.svg"} />
				</div>
				<div className="flex-grow-1">{amount}</div>
				<div onClick={onAdd}>
					<img className="align-self-center p-2" src={"images/plus.svg"} />
				</div>
			</div>
		</div>
	);
};

export default CostPanelItem;
