import React from "react";
import DescriptiveItem from "../atoms/descriptive-item";
import CostsPanel from "../molecules/costs-panel";
import { Element } from 'react-scroll';

const HomeCosts = () => {
	return (
		<Element name="costs-container">
			<div className="home-costs d-flex flex-column justify-content-center align-items-center">
				<div className="container">
					<div className="mb-3">
						<DescriptiveItem title={"Izmaksas:"} />
					</div>
					<div className="d-flex flex-column flex-md-row justify-content-between align-items-center align-content-center ">
						<div
							className={
								"flex-grow-1 align-items-center d-flex order-2 order-md-1 my-4 my-md-0"
							}
						>
							<img
								className="align-self-center phone img-fluid"
								src={"images/phone_card.png"}
								style={{ maxHeight: 745 }}
							/>
						</div>

						<div style={{ width: 80 }} />
						<div className="subscribtion d-flex flex-column justify-content-start order-1 order-md-2">
							<div className="title mt-5 mt-md-0">
								<b>45 EUR</b>/mēnesī par objektu
							</div>
							<ul class="">
								<li class="">
									<b>Beztermiņa</b> līgums
								</li>
								<li class="">
									<b>Bezmaksas</b> apmācība
								</li>
								<li class="">
									<b>Uzticams</b> tehniskais atbalsts
								</li>
								<li class="">
									<b>Droša</b> datu glabāšana
								</li>
							</ul>
						</div>
					</div>

					<CostsPanel />
				</div>
			</div>
		</Element>
	);
};

export default HomeCosts;
