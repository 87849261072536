import React from "react";
import DescriptiveItem from "../atoms/descriptive-item";
import FunctionItem from "../atoms/function-item";
import HowItWorksItem from "../atoms/how-it-works-item";
import SolutionCard from "../atoms/solution-card";
import SystemItem from "../atoms/system-item";
import { Element } from "react-scroll";

const data = [
	{
		title: "NFC",
		image: "images/how-it-works/hand_ico.png"
	},
	{
		title: "Iekārta vai app",
		image: "images/how-it-works/phone_ico.png"
	},
	{
		title: "Edlus.app serveris un web sistēma",
		image: "images/how-it-works/pc_ico.png"
	},
	{
		title: "VEDLUDB sistēma",
		image: "images/how-it-works/vedludb.png"
	}
];

const system_data = [
	{
		title: "Objektu saraksts",
		image: "images/system/1.png"
	},
	{
		title: "Objektu monitorings",
		image: "images/system/2.png"
	},
	{
		title: "Darbinieku monitorings",
		image: "images/system/3.png"
	},
	{
		title: "Darbinieku veidošana",
		image: "images/system/4.png"
	},
	{
		title: "Objektu veidošana",
		image: "images/system/5.png"
	},
	{
		title: "Atskaites",
		image: "images/system/6.png"
	}
];

const functions_data = [
	{
		title: "Darbinieku reģistrācija izmantojot NFC kartes",
		image: "images/functions/12.svg"
	},
	{
		title: "Darbinieku manuāla reģistrācija",
		image: "images/functions/9.svg"
	},
	{
		title: "Darbinieku reģistrācija izmantojot viedtālruni",
		image: "images/functions/1.svg"
	},
	{
		title: "Alkohola kontrole",
		image: "images/functions/2.svg"
	},
	{
		title: "Vairāku līmeņa tiesību piešķiršana",
		image: "images/functions/3.svg"
	},
	{
		title: "Virsstundu brīdinājumi",
		image: "images/functions/4.svg"
	},
	{
		title: "Darbinieku appliecību veidošana",
		image: "images/functions/5.svg"
	},
	{
		title: "Objekta ģeozonas definēšana atvērta tipa objektiem",
		image: "images/functions/6.svg"
	},
	{
		title: "Reāla laika darbinieku un viesu reģistrēšanās dati no objekta",
		image: "images/functions/10.svg"
	},
	{
		title: "Atskaites un žurnāli",
		image: "images/functions/7.svg"
	},
	{
		title: "Datu eksports",
		image: "images/functions/8.svg"
	},
	{
		title: "Datu sinhorinzācija ar VID",
		image: "images/functions/11.svg"
	}
];

const HomeSolutions = () => {
	return (
		<div className="home-solutions d-flex flex-column justify-content-center align-items-center">
			<div className="container">
				<Element name="solutions-container">
					<div className="d-flex justify-content-center align-items-center">
						<DescriptiveItem
							title={"Risinājumi:"}
							subtitle={
								"Darbinieku identifikācijas un darba laika reģistrēšanas risinājumi ir ērti un pielāgojami klienta vajadzībām, izmantojot NFC iekārtu vai viedtālruni ar Edlus.app lietotni, kā arī NFC kartes vai uzlīmes."
							}
						/>
					</div>

					<div className="cards row d-flex flex-row justify-content-between align-items-center align-content-center">
						<div className="col-xs-12 col-lg-6">
							<SolutionCard
								title={"Aplikācijas risinājums"}
								subtitle={
									"Edlus.app mobilā lietotne nodrošina darbinieku sasaisti ar NFC kartēm, to tālāk izmantojot arī nodarbināto personu darba laika reģistrēšanai. Fiksētie dati automātiski tiek nosūtīti Edlus.app sistēmā."
								}
								image={"images/icons/phone_ico.png"}
							/>
						</div>
						<div className="col-xs-12 col-lg-6 mt-5 mt-lg-0">
							<SolutionCard
								title={"NFC lasītāju risinājums"}
								subtitle={
									"Nodarbināto personu darba laika reģistrēšana ir iespējama ar NFC datu lasīšanas iekārtu, tās veidu pielāgojot klienta vajadzībām. Iekārtas risinājums ir piemērots liela darbinieku skaita reģistrēšanai, fiksētos datus pārredzot Edlus.app sistēmā."
								}
								image={"images/icons/device_ico.png"}
							/>
						</div>
					</div>
				</Element>

				<div className="how-it-works d-flex flex-column align-items-center">
					<DescriptiveItem title={"Kā tas darbojas:"} />

					<div className="row d-flex flex-row justify-content-between align-items-center">
						{data.map((key, index) => {
							return (
								<div className="col d-flex flex-column flex-lg-row">
									<HowItWorksItem
										title={data[index].title}
										image={data[index].image}
									/>
									{index !== data.length - 1 && (
										<div
											className="arrow align-self-center my-4 my-lg-0"
											style={{
												marginLeft: 48,
												marginRight: 48
											}}
										>
											<img
												className="image"
												src={"images/double_arrow_ico.svg"}
											/>
										</div>
									)}
								</div>
							);
						})}
					</div>
				</div>
				<Element name="system-container">
					<div className="system d-flex flex-column align-items-center">
						<DescriptiveItem
							title={"Sistēma:"}
							subtitle={
								"Edlus.app ir elektroniskās darba laika uzskaites sistēma, kas nodrošina nodarbināto personu darba laika elektronisku reģistrāciju, uzskaiti un fiksēto datu glabāšanu, tos tālāk nododot vienotajā elektroniskās darba laika uzskaites datubāzē.\nEdlus.app ir likumdošanai atbilstošs, ērts un izdevīgs risinājums, kas nodrošina uzņēmuma procesu kontroli, optimizēšanu un caurspīdību."
							}
						/>

						<div className="row d-flex flex-row align-items-center mt-5 mt-md-0">
							<div className="col-12 col-md-6">
								{system_data.map((_, index) => {
									if (index > 2) return null;
									return (
										<div className="my-3 my-lg-5">
											<SystemItem
												title={system_data[index].title}
												image={system_data[index].image}
											/>
										</div>
									);
								})}
							</div>
							<div className="col-12 col-md-6 myt-190">
								{system_data.map((_, index) => {
									if (index < 3) return null;
									return (
										<div className="my-3 my-lg-5">
											<SystemItem
												title={system_data[index].title}
												image={system_data[index].image}
											/>
										</div>
									);
								})}
							</div>
						</div>
					</div>
				</Element>
				<div className="functions d-flex flex-column align-items-center">
					<DescriptiveItem
						title={"Funkcijas:"}
						subtitle={
							"Ar Edlus.app darba laika uzskaite ir ērta un uzticama, nodrošinot procesam nepieciešamās funkcijas."
						}
					/>
					<div className="row w-100 justify-content-center">
						<div className="col-12 col-md-9">
							<div className="row">
								{functions_data.map((key, index) => {
									return (
										<div key={key} className="col-6 col-md-4">
											<FunctionItem
												title={functions_data[index].title}
												image={functions_data[index].image}
											/>
										</div>
									);
								})}
							</div>
						</div>
					</div>
				</div>
				<div className="position-absolute bg-bubble"></div>
			</div>
		</div>
	);
};

export default HomeSolutions;
