import React, { useState } from "react";
import { Element } from "react-scroll";
import { postUrl } from "../../api/common";

const data = [
	{
		title: "50+",
		subtitle: "Objekti"
	},
	{
		title: "2’800",
		subtitle: "Reģistrētie darbinieki"
	},
	{
		title: "40",
		subtitle: "Uzņemumi"
	},
	{
		title: "500’000",
		subtitle: "Autorizāciju skaits sistēmā"
	}
];
const HomeTryRow = () => {
	const [email, setEmail] = useState("");
	const [emailSuccess, setEmailSuccess] = useState(false);

	const sendMail = async () => {
		await postUrl(
			"http://edlus.devintry.com:5789/api/send-mail",
			{
				to: "info@edlus.app",
				from: "info@edlus.app",
				subject: `Inquiry for Edlus from ${email}`,
				text: `Jauns pieteikums no ${email}`
			},
			{
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json"
				}
			}
		);
		await setEmail("");
		await setEmailSuccess(true);
	};

	return (
		<Element name="try-container">
			<div className="home-try-row d-flex justify-content-center align-items-center">
				<div className="container">
					<div className="bg px-5 px-lg-0 py-4 py-lg-0 d-flex">
						<div className="row w-100">
							<div className="col-xs-12 col-lg-5 offset-lg-1 d-flex flex-column justify-content-center align-items-start">
								<div className="title my-0 my-lg-2 mt-3 mt-lg-0">
									Izmēģini edlus.app
								</div>
								<div className="subtitle my-2">
									Iepazīsties pirms pieņem lēmumu – piesakies izmēģinājuma
									periodam.
								</div>
							</div>
							<div className="col-xs-12 col-lg-6 d-flex flex-column justify-content-center align-items-center">
								{/* mt-5 mb-2"> */}
								<div className="row email-input d-flex flex-row justify-content-around align-items-center align-self-center mt-sm-3 mt-lg-5">
									<input
										className="px-0 px-sm-4 pl-2 pl-sm-0"
										type="text"
										placeholder="@tava epasta adrese"
										value={email}
										onChange={(e) => setEmail(e?.target?.value)}
									></input>
									<div
										className="button justify-content-center align-items-center d-none d-lg-flex hover-opacity"
										onClick={() => sendMail()}
									>
										<div>Piesakies</div>
									</div>
									<div className="justify-content-center align-items-center d-flex d-lg-none px-0 px-sm-4 pr-2 pr-sm-0">
										<img className="" src="images/mail_ico.svg"></img>
									</div>
								</div>
								<div className="row d-flex flex-row justify-content-start justify-content-lg-center align-items-start align-items-lg-center no-gutters my-2 w-100">
									<ul class="ul-white-row my-auto ">
										<li class="mx-0 mx-lg-3">Izmēģini 5 dienas bez maksas</li>
										<li class="mx-0 mx-lg-3">Atcel, kad vēlies</li>
									</ul>
								</div>
								{emailSuccess && (
									<div className="pt-2">
										Paldies par pieteikumu! Mēs ar Jums sazināsimies tuvāko
										dienu laikā.
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</Element>
	);
};

export default HomeTryRow;
