import React from "react";
import DescriptiveItem from "../atoms/descriptive-item";
import CostsPanel from "../molecules/costs-panel";

const ticks = [
	"Sasaiste ar VEDLUDB sistēmu",
	"Auditācijas pierakstu kontrolsumma",
	"Ārējā drošības pārbaude"
];

const HomeThirdRow = () => {
	return (
		<div className="home-third-row d-flex flex-column justify-content-center align-items-center ">
			<div className="container d-flex flex-column justify-content-center align-content-center mb-5 mb-lg-0 h-100">
				<div className="row no-gutters h-100">
					<div className="col-12 col-lg-6 w-100">
						<div className="image">
							<div className="blue-bg" />
							<img
								className="align-self-center img-fluid"
								src={"images/phone_reader_ico.png"}
							/>
						</div>
					</div>
					<div className="col-12 col-lg-6 subscribtion d-flex flex-column justify-content-center align-content-center">
						<div className="row no-gutters">
							<div className="title">
								Ar Edlus.app darbinieku reģistrāciju var veikt, izmantojot
								dažādus tehniskos risinājumus.
							</div>
						</div>
						<div className="row no-gutters mt-4">
							<div className="subtitle">
								Atkarībā no objekta specifikas Edlus.app var tikt pielāgots,
								izmantojot mobilo lietotni vai NFC lasītāju, turniketu un
								alkometru.
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default HomeThirdRow;
