import React, { useState } from "react";
import { FormFeedback, Input, Label } from "reactstrap";
import { Field } from "formik";

const HowItWorksItem = ({ title = "Title", image = null }) => {
	return (
		<div className="how-it-works-item d-flex flex-column justify-content-between align-items-center">
			<img className="image" src={image} />
			<div className="title">{title}</div>
		</div>
	);
};

export default HowItWorksItem;
 