import React, { useState } from "react";
import { FormFeedback, Input, Label } from "reactstrap";
import { Field } from "formik";

const DescriptiveItem = ({ title = "Title", subtitle = null }) => {
	return (
		<div className="descriptive-item mx-auto">
			<div className="title">{title}</div>
			{subtitle !== null && (
				<div className="subtitle" style={{ whiteSpace: "pre-wrap" }}>
					{subtitle}
				</div>
			)}
		</div>
	);
};

export default DescriptiveItem;
