import { ReduxActions } from "../../utils";

const initialState = {
	products: [
		{
			id: 1,
			title: "Objektu skaits",
			price_text: "45 EUR",
			image: null,
			amount: 0,
			price: 45.0
		},
		{
			id: 2,
			title: "Objekta ilgums",
			price_text: "Mēneši",
			image: null,
			amount: 0,
			price: 0.0
		},
		{
			id: 3,
			title: "NFC lasītājs āra lietošanai",
			price_text: "220 EUR/gab",
			image: "images/calculator/nfc_no_display.png",
			amount: 0,
			price: 220.0
		},
		{
			id: 4,
			title: "NFC lasītājs āra lietošanai ar displeju",
			price_text: "280 EUR/gab",
			image: "images/calculator/nfc_display.png",
			amount: 0,
			price: 280.0
		},
		{
			id: 5,
			title: "NFC lasītājs āra lietošanai ar displeju un GSM moduli",
			price_text: "300 EUR/gab",
			image: "images/calculator/nfc_gsm.png",
			amount: 0,
			price: 300.0
		},
		{
			id: 6,
			title: "Mobilā aplikācija",
			price_text: "0 EUR",
			image: "images/calculator/phone.png",
			amount: 0,
			price: 0.0
		},
		{
			id: 7,
			title: "NFC uzlīmes",
			price_text: "0.70 EUR",
			image: "images/calculator/stickers_ico.png",
			amount: 0,
			price: 0.7
		},
		{
			id: 8,
			title: "NFC plastikāta kartes",
			price_text: "1.70 EUR",
			image: "images/calculator/card.png",
			amount: 0,
			price: 1.7
		}
	]
};

export default function appReducer(state = initialState, action) {
	switch (action.type) {
		case ReduxActions.PRODUCT_INCREASE: {
			if (action.data) {
				const { product } = action.data;
				let products = state?.products;
				const find = state?.products?.find((f) => f?.id === product?.id);

				if (find) {
					find.amount = Math.max(0, Math.min(find.amount + 1, 999));
				} else {
					products = [...state?.products, product];
				}
				return {
					...state,
					products: [...products]
				};
			}
			return state;
		}

		case ReduxActions.PRODUCT_DECREASE: {
			if (action.data) {
				const { product } = action.data;
				let products = state?.products;
				const find = state?.products?.find((f) => f?.id === product?.id);

				if (find) {
					find.amount = Math.max(0, Math.min(find.amount - 1, 999));
				} else {
					products = [...state?.products, product];
				}
				return {
					...state,
					products: [...products]
				};
			}
			return state;
		}

		case ReduxActions.PRODUCTS_CLEAR: {
			return {
				...state,
				products: initialState.products
			};
		}
	}

	return state;
}
