import React, { useState } from "react";
import { FormFeedback, Input, Label } from "reactstrap";
import { Field } from "formik";
import DescriptiveItem from "./descriptive-item";

const SystemItem = ({
	title = "Title",
	image = null
}) => {
	return (
		<div className="system-item d-flex flex-column align-content-center align-items-center">
			<div className={"d-flex flex-grow-1 align-items-center"}>
				<img className="align-self-center img-fluid" src={image} />
			</div>
			<div className="title">{title}</div>
		</div>
	);
};

export default SystemItem;
