import React from "react";

const HomeSecondRow = () => {
	return (
		<div className="second-row d-flex flex-column justify-content-center align-items-center">
			<div className="container d-flex flex-column justify-content-center align-items-center">
				<div className="title row mt-5 mt-lg-0">
					{
						"Vairāk kā 3’000+ aktīvo lietotāju, kas\nikdienā izmanto edlus.app darba laika uzskaitei"
					}
				</div>

				<div className="logos mb-5 mb-lg-0">
					<div className="row row-cols-3 row-cols-md-5 row-cols-lg-6">
						{Array.from({ length: 14 }, (_, index) => {
							const imgIndex = index + 13;
							const imgName = `edlus_prezentacija_2-${imgIndex}.png`;

							return (
								<>
									<div className={"col d-flex justify-content-center"}>
										<img
											className="logo-item"
											src={`images/icons/${imgName}`}
										/>
									</div>
								</>
							);
						})}
					</div>
				</div>
			</div>
		</div>
	);
};

export default HomeSecondRow;
