import React, { useState } from "react";

const FunctionItem = ({
	title = "Title",
	image = null
}) => {
	return (
		<div className="function-item d-flex flex-column align-content-center align-items-center flex-fill">
			<div className={"flex-grow-1 align-items-center"}>
				<img className="align-self-center img-fluid" src={image} />
			</div>
			<div className="title mt-3">{title}</div>
		</div>
	);
};

export default FunctionItem;
