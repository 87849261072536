import React, { useState } from "react";
import {
	Collapse,
	Navbar,
	NavbarToggler,
	NavbarBrand,
	Nav,
	NavItem,
	NavbarText,
	NavLink
} from "reactstrap";
import { scroller } from "react-scroll";

const NavigationBar = () => {
	const [isOpen, setIsOpen] = useState(false);
	const toggle = () => setIsOpen(!isOpen);

	const srollTo = (name) => {
		scroller.scrollTo(name, {
			duration: 100,
			delay: 0,
			smooth: true
			// containerId: "ContainerElementID",
			// offset: 50 // Scrolls to element + 50 pixels down the page
		});
	};
	
	return (
		<div>
			<Navbar
				color="inverse"
				light
				expand="md"
				className="navigation-bar container"
			>
				<NavbarBrand href="/">
					<img src="images/logo/logo.png" alt="logo" className="logo" />
				</NavbarBrand>
				<NavbarToggler onClick={toggle} />
				<Collapse isOpen={isOpen} navbar>
					<Nav className="mx-auto mr-auto" navbar>
						<NavItem className="accent mx-lg-5 px-0 px-xl-5">
							<div
								className="hover-opacity nav-link"
								onClick={() => srollTo("solutions-container")}
							>
								Risinājumi
							</div>
						</NavItem>
						<NavItem className="accent mx-lg-5 px-0 px-xl-5">
							<div
								className="hover-opacity nav-link"
								onClick={() => srollTo("system-container")}
							>
								Sistēma
							</div>
						</NavItem>
						<NavItem className="accent mx-lg-5 px-0 px-xl-5">
							<div
								className="hover-opacity nav-link"
								onClick={() => srollTo("costs-container")}
							>
								Izmaksas
							</div>
						</NavItem>
					</Nav>
					<NavbarText>
						<div className="d-flex flex-row align-content-center justify-content-between">
							<div
								className="align-self-center mx-2 hover-opacity"
								style={{ opacity: 0.4 }}
							>
								<a href="http://www.edlus.app/sistema">Login</a>
							</div>
							<div
								className="button-common d-flex align-content-center justify-content-center mx-2 hover-opacity"
								style={{ height: 64 }}
								onClick={() => srollTo("try-container")}
							>
								<div className="align-self-center px-4">Piesakies</div>
							</div>
						</div>
					</NavbarText>
				</Collapse>
			</Navbar>
		</div>
	);
};

{
	/* <Navbar
			color="inverse"
			light
			expand="md"
			className="navigation-bar container"
		>
			<NavbarBrand href="/" style={{ bottom: -20 }}>
				<img src="images/logo/logo.png" alt="logo" className="logo" />
			</NavbarBrand>
			<NavbarToggler onClick={toggle} />
			<Collapse isOpen={true} navbar>
				<Nav className="ml-auto" navbar>
					<NavItem>
						<NavLink href="/components/">Components</NavLink>
					</NavItem>
					<NavItem>
						<NavLink href="https://github.com/reactstrap/reactstrap">
							GitHub
						</NavLink>
					</NavItem>
					<UncontrolledDropdown nav inNavbar>
						<DropdownToggle nav caret>
							Options
						</DropdownToggle>
						<DropdownMenu right>
							<DropdownItem>Option 1</DropdownItem>
							<DropdownItem>Option 2</DropdownItem>
							<DropdownItem divider />
							<DropdownItem>Reset</DropdownItem>
						</DropdownMenu>
					</UncontrolledDropdown>
				</Nav>
				<NavbarText>Simple Text</NavbarText>
			</Collapse>
		</Navbar>
	); */
}

export default NavigationBar;
