import axios from "axios";
import {
	departmentsUpdateAction,
	invoicesUpdateAction,
	personsUpdateAction
} from "../redux/actions/app-state";
import { get, post, put, validationWithStatus } from "./common";

export const config = {
};

export const client = axios.create({
	baseURL: config.baseUrl,
	timeout: 20000,
	headers: {
		Accept: "application/json"
	}
});

client.interceptors.request.use((config) => {
	// config.headers.Authorization.Username = "avalanche-ttu";
	// config.headers.Authorization.Password = "tRswaWTCLPLAXPFG";

	// config.headers["Authorization"] =
	// 	"Basic YXZhbGFuY2hlLXR0dTp0UnN3YVdUQ0xQTEFYUEZH";

	return config;
});

client.interceptors.response.use(
	(response) => {
		// console.log(
		// 	"response :: url " + JSON.stringify(response?.config?.url, null, 4)
		// );
		// console.log("response :: " + JSON.stringify(response?.data, null, 4));
		return response;
	},
	function (error) {
		console.log("error :: " + JSON.stringify(error, null, 4));

		return Promise.reject(error);
	}
);
