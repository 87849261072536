import React, { useEffect, useState } from "react";
import CostPanelItem from "../atoms/const-panel-item";
import CostPanelItemNew from "../atoms/const-panel-item-new";
import Slider from "rc-slider";

const products = [
	{
		id: 1,
		title: "Abonēšanas maksa par objektu",
		price_text: "45 EUR",
		image: null,
		price: 45.0
	},
	{
		id: 2,
		title: "Objekta ilgums",
		price_text: "Mēneši",
		image: null,
		price: 0.0
	},
	{
		id: 3,
		title: "NFC lasītājs āra lietošanai",
		price_text: "220 EUR/gab",
		image: "images/calculator/nfc_no_display.png",
		price: 220.0
	},
	{
		id: 4,
		title: "NFC lasītājs āra lietošanai ar displeju",
		price_text: "280 EUR/gab",
		image: "images/calculator/nfc_display.png",
		price: 280.0
	},
	{
		id: 5,
		title: "NFC lasītājs āra lietošanai ar displeju un GSM moduli",
		price_text: "300 EUR/gab",
		image: "images/calculator/nfc_gsm.png",
		price: 300.0
	},
	{
		id: 6,
		title: "Mobilā aplikācija",
		price_text: "0 EUR",
		image: "images/calculator/phone.png",
		price: 0.0
	},
	{
		id: 7,
		title: "NFC uzlīmes",
		price_text: "0.70 EUR",
		image: "images/calculator/stickers_ico.png",
		price: 0.7
	},
	{
		id: 8,
		title: "NFC plastikāta kartes",
		price_text: "1.70 EUR",
		image: "images/calculator/card.png",
		price: 1.7
	}
];

const CostsPanel = ({}) => {
	const [stagedProducts, setStagedProducts] = useState([products[0]]);
	const [total, setTotal] = useState(0);
	const [sliderValue, setSliderValue] = useState(3);
	const [selectedProducts, setSelectedProducts] = useState([1, 1, 1]);

	const selectProduct = (id) => {
		const find = selectedProducts.find((f) => f === id);
		const product = products.find((f) => f.id === id);

		if (find) {
			setSelectedProducts(selectedProducts.filter((f) => f !== id));
			setStagedProducts(stagedProducts.filter((f) => f.id !== id));
		} else {
			setSelectedProducts([...selectedProducts, id]);
			setStagedProducts([...stagedProducts, product]);
		}
	};

	const increse = (id) => {
		const find = selectedProducts.find((f) => f === id);
		const product = products.find((f) => f.id === id);

		setSelectedProducts([...selectedProducts, id]);
		if (find) {
			return;
		}
		setStagedProducts([...stagedProducts, product]);
	};

	const decrease = (id) => {
		const find = selectedProducts.find((f) => f === id);
		const filtered = selectedProducts.filter((f) => f === id);

		if (find) {
			let firstHit = false;
			setSelectedProducts(
				selectedProducts.filter((f) => {
					if (f !== id) {
						return true;
					}

					if (!firstHit) {
						firstHit = true;
						return false;
					}
					return true;
				})
			);

			if (filtered?.length === 1) {
				firstHit = false;
				setStagedProducts(stagedProducts.filter((f) => f.id !== id));
			}
		}
	};

	useEffect(() => {
		var totalCosts = 0;
		selectedProducts.forEach((item) => {
			const find = products.find((f) => f.id === item);

			if (find) {
				totalCosts = totalCosts + find.price;
			}
		});
		setTotal(totalCosts.toFixed(2));
	}, [selectedProducts]);

	return (
		<div className="costs-panel d-flex flex-column align-content-center align-items-center">
			<div className="title align-self-start mb-5">
				{"Izmanto cenu kalkulatoru un aprēķini objekta izmaksas:"}
			</div>
			<div className="row d-flex flex-row align-content-center align-items-center mt-1 mt-md-5 mt-lg-0 w-100">
				<div className={"col-12 col-lg-7"}>
					<div className="row no-gutters">
						<div className={"col-12"}>
							<div className="section-title align-self-start">
								{"Objekta ilgums"}
							</div>

							<div className="my-3">
								<div
									className="d-flex flex-column w-100"
									style={{ maxWidth: 339 }}
								>
									<div className="d-flex flex-row justify-content-start py-3">
										<div className="pr-3" style={{}}>
											Mēneši:
										</div>
										<div style={{ fontWeight: "bold" }}>{sliderValue}</div>
									</div>

									<Slider
										min={1}
										max={36}
										defaultValue={3}
										startPoint={3}
										value={sliderValue}
										onChange={(newValue) => {
											const filtered = selectedProducts.filter((f) => f !== 1);

											setSelectedProducts([
												...filtered,
												...Array.from({ length: newValue }, (_, index) => 1)
											]);

											setSliderValue(newValue);
										}}
										trackStyle={{
											backgroundColor: "#0055ff",
											height: 2,
											maxWidth: 339
										}}
										handleStyle={{
											borderColor: "#0055ff",
											height: 20,
											width: 20,
											marginTop: -10,
											backgroundColor: "#0055ff"
										}}
										railStyle={{
											backgroundColor: "#0055ff",
											height: 2,
											maxWidth: 339
										}}
									/>
									<div className="d-flex flex-row justify-content-between">
										<div style={{ color: "#d0d2d3" }}>1</div>
										<div style={{ color: "#d0d2d3" }}>36</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="row no-gutters mx-n2">
						<div className={"col-12"}>
							<div className="section-title align-self-start">
								{"Iekārtu izmaksas"}
							</div>
						</div>
						<div className={"col-6 my-3 px-2"}>
							<CostPanelItemNew
								selected={selectedProducts.includes(products[5].id)}
								title={products[5].title}
								subtitle={products[5].price_text}
								image={products[5].image}
								amount={products[5].amount}
								onClick={() => {
									selectProduct(products[5].id);
								}}
							/>
						</div>
						<div className={"col-6 my-3 px-2"}>
							<CostPanelItemNew
								selected={selectedProducts.includes(products[3].id)}
								title={products[3].title}
								subtitle={products[3].price_text}
								image={products[3].image}
								amount={products[3].amount}
								onClick={() => {
									selectProduct(products[3].id);
								}}
							/>
						</div>
						<div className={"col-6 my-3 px-2"}>
							<CostPanelItemNew
								selected={selectedProducts.includes(products[2].id)}
								title={products[2].title}
								subtitle={products[2].price_text}
								image={products[2].image}
								amount={products[2].amount}
								onClick={() => {
									selectProduct(products[2].id);
								}}
							/>
						</div>
						<div className={"col-6 my-3 px-2"}>
							<CostPanelItemNew
								selected={selectedProducts.includes(products[4].id)}
								title={products[4].title}
								subtitle={products[4].price_text}
								image={products[4].image}
								amount={products[4].amount}
								onClick={() => {
									selectProduct(products[4].id);
								}}
							/>
						</div>
					</div>
					<div className="row">
						<div className={"col-6 my-3 "}>
							<CostPanelItem
								title={products[7].title}
								subtitle={products[7].price_text}
								image={products[7].image}
								amount={
									selectedProducts.filter((f) => f === products[7].id)?.length
								}
								onAdd={() => increse(products[7].id)}
								onReduce={() => decrease(products[7].id)}
							/>
						</div>
						<div className={"col-6 my-3 "}>
							<CostPanelItem
								title={products[6].title}
								subtitle={products[6].price_text}
								image={products[6].image}
								amount={
									selectedProducts.filter((f) => f === products[6].id)?.length
								}
								onAdd={() => increse(products[6].id)}
								onReduce={() => decrease(products[6].id)}
							/>
						</div>
					</div>
				</div>
				<div className="summary col-lg-4 offset-lg-1 d-none d-lg-flex flex-column p-5">
					{stagedProducts && (
						<div className="d-flex flex-column align-content-center align-items-start my-auto">
							{stagedProducts.map((key, index) => {
								const product = stagedProducts[index];
								const amount = selectedProducts.filter(
									(f) => f === product?.id
								);

								return (
									<div className="d-flex flex-column mt-3">
										<div>{`${amount.length} x ${product.price_text}`}</div>
										<div>{product.title}</div>
									</div>
								);
							})}
						</div>
					)}
					<div className="total mt-4 py-4">{`Kopā: ${total} EUR`}</div>
					<div className="button d-flex flex-row align-content-center align-items-center my-4 px-5">
						<div className="flex-grow-1">Veikt pasūtījumu</div>
						{/* <div>
							<img
								className="align-self-center p-2"
								src={"images/cart_blue.svg"}
							/>
						</div> */}
					</div>
				</div>

				<div className="summary d-flex d-lg-none flex-column mx-auto my-auto pt-5">
					<div className="button d-flex flex-row align-content-center align-items-center my-4 px-5">
						{/* <div>
							<img
								className="align-self-center p-2"
								src={"images/cart_white.svg"}
							/>
						</div> */}
						<div className="flex-grow-1">{`Kopā: ${total} EUR`}</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CostsPanel;
