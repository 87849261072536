import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import { persistStore, persistCombineReducers } from "redux-persist";

import thunk from "redux-thunk";
import reducers from "../reducers/index";
import storage from "redux-persist/lib/storage";

// import { ReduxActions } from "../../utils";

const reduxPersistConfig = {
	key: "root",
	storage: storage,
	blacklist: ["appState"]
};

const reducer = persistCombineReducers(reduxPersistConfig, reducers);
const middleware = [thunk];
const rootReducer = (state, action) => {
	// // when a logout action is dispatched it will reset redux state
	// if (action.type === ReduxActions.USER_LOGGED_OUT) {
	// 	AsyncStorage.removeItem("persist:root");
	// 	state = undefined;
	// }

	return reducer(state, action);
};

const configureStore = () => {
	const composeEnhancer =
		window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

	const store = createStore(
		rootReducer,
		composeEnhancer(applyMiddleware(...middleware))
	);
	const persistor = persistStore(store, null, () => {
		store.getState();
	});

	return { persistor, store };
};

export default configureStore;
