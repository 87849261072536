import React, { useState } from "react";
import { FormFeedback, Input, Label } from "reactstrap";
import { Field } from "formik";
import DescriptiveItem from "./descriptive-item";

const CostPanelItemNew = ({
	title = "Title",
	subtitle = "subtitle",
	amount = 0,
	image = null,
	selected = false,
	onClick = null
}) => {
	return (
		// d-flex flex-row align-content-center align-items-start
		<div
			onClick={() => {
				if (onClick) {
					onClick();
				}
			}}
			className={`cost-panel-item-new h-100 w-100 px-2 py-2 px-md-4 py-md-2 ${
				selected ? "selected" : ""
			}`}
		>
			<div className="row no-gutters d-none d-md-flex">
				<div className={"col-3 align-self-center"}>
					<img className="align-self-center img-fluid" src={image} />
				</div>
				<div className="col align-self-center">
					<div className="title">{title}</div>
					<div className="subtitle">{subtitle}</div>
				</div>
			</div>

			<div className="d-flex d-md-none flex-column">
				<div className={"align-self-start"}>
					<img
						className="align-self-center img-fluid"
						src={image}
						style={{ height: 40 }}
					/>
				</div>
				<div className="align-self-center">
					<div className="title">{title}</div>
					<div className="subtitle">{subtitle}</div>
				</div>
			</div>
		</div>
	);
};

export default CostPanelItemNew;
