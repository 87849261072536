import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, Switch, Redirect } from "react-router-dom";
import NavigationBar from "./components/molecules/navigation-bar";
import { Home } from "./pages/home";

export const Routes = () => {
	const appState = useSelector((state) => state);

	useEffect(() => {
		
	}, []);

	return (
		<div className="h-100">
			<NavigationBar />
			<Switch>
				<Route exact path="/" component={Home} />
				<Route exact path="/home">
					<Redirect to="/" />
				</Route>
			</Switch>
		</div>
	);
};
