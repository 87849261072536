import appState from './app-state';

const rehydrated = (state = false, action) => {
	switch (action.type) {
		case "persist/REHYDRATE":
			return true;
		default:
			return state;
	}
};

export default {
  rehydrated,
  appState
};
