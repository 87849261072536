import { client } from "./index";
import axios from "axios";

const errorValue = { success: false, error: true, message: "failed" };

export const validationEmpty = {
	success: true,
	error: "none"
};

export const validationWithStatus = {
	success: true,
	error: "none",
	checkStatus: true
};

export const validationWithData = {
	success: true,
	error: "none",
	checkData: true
};

export const validationFull = {
	success: true,
	error: "none",
	checkData: true,
	checkStatus: true
};

export const buildValidation = (
	error = "none",
	success = true,
	checkData = false,
	checkStatus = false
) => {
	return {
		success: success,
		error: error,
		checkData: checkData,
		checkStatus: checkStatus
	};
};

const needValidation = (validation) => {
	return validation !== null && validation !== undefined;
};

const tryCheckStatus = (validation, response) => {
	let success = true;
	if (!needValidation(validation)) {
		return success;
	}

	if (validation.checkStatus && validation.checkStatus === true) {
		if (response && response.status !== null && response.status !== undefined) {
			success = response.status == 200 || response.status == 201;
		} else {
			success = false;
		}
	}

	return success;
};

const tryCheckIfDataValid = (validation, response) => {
	let success = true;
	if (!needValidation(validation)) {
		return success;
	}

	if (validation.checkData && validation.checkData === true) {
		if (response.data && response.data.data) {
			success = response.data.data !== null && response.data.data !== undefined;
		} else {
			success = false;
		}
	}

	return success;
};

export const get = (url, data, config, validation, onResponse) => {
	return new Promise(async (resolve, reject) => {
		return client
			.get(url, data, config)
			.then((response) => {
				return resolve(onResponse(response));
			})
			.catch(function (error) {
				var errorMsg = error;

				if (error.response) {
					errorMsg = error.response.data;
				}
				return reject();
			});
	}).catch((err) => {
		return { success: false, error: err };
	});
};

export const post = (url, data, config, validation, onResponse) => {
	return new Promise(async (resolve, reject) => {
		return client
			.post(url, data, config)
			.then((response) => {
				return resolve(onResponse(response));
			})
			.catch(function (error) {
				var errorMsg = error;

				if (error.response) {
					errorMsg = error.response.data;
				}
				return reject();
			});
	}).catch((err) => {
		return { success: false, error: err };
	});
};

export const put = (url, data, config, validation, onResponse) => {
	return new Promise(async (resolve, reject) => {
		return client
			.put(url, data, config)
			.then((response) => {
				return resolve(onResponse(response));
			})
			.catch(function (error) {
				var errorMsg = error;

				if (error.response) {
					errorMsg = error.response.data;
				}
				return reject();
			});
	}).catch((err) => {
		return { success: false, error: err };
	});
};

export const postUrl = (url, data, config, validation, onResponse) => {
	return new Promise(async (resolve, reject) => {
		return axios
			.post(url, data, config)
			.then((response) => {
				return resolve(onResponse(response));
			})
			.catch(function (error) {
				var errorMsg = error;

				if (error.response) {
					errorMsg = error.response.data;
				}
				return reject();
			});
	}).catch((err) => {
		return { success: false, error: err };
	});
};
