import React, { useState } from "react";
import { FormFeedback, Input, Label } from "reactstrap";
import { Field } from "formik";

const StatsItem = ({ title = "Title", subtitle = "Placeholder" }) => {
	return (
		<div className="stats-item">
			<div className="title">{title}</div>
			<div className="subtitle">{subtitle}</div>
		</div>
	);
};

export default StatsItem;
