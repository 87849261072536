import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import ButtonPanel from "../../components/molecules/button-panel";
import HomeCosts from "../../components/organisms/home-costs";
import HomeFourthRow from "../../components/organisms/home-fourth-row";
import HomeReviewRow from "../../components/organisms/home-review-row";
import HomeSecondRow from "../../components/organisms/home-second-row";
import HomeSolutions from "../../components/organisms/home-solutions";
import HomeTryRow from "../../components/organisms/home-try-row";
import { Permissions } from "../../services";
import { InvoiceStatusMap } from "../../utils/mappings";
import HomeFirstRow from "../../components/organisms/home-first-row";
import HomeThirdRow from "../../components/organisms/home-third-row";

export const Home = () => {
	return (
		<div className="d-flex flex-column">
			<HomeFirstRow />
			<HomeSecondRow />
			<HomeThirdRow />
			<HomeFourthRow />
			<HomeSolutions />
			<HomeReviewRow />
			<HomeCosts />
			<HomeTryRow />
		</div>
	);
};
