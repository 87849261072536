import React from "react";
import StatsItem from "../atoms/stats-item";

const data = [
	{
		title: "50+",
		subtitle: "Objekti",
		order_lg: "order-lg-1",
		order_sm: "order-1"
	},
	{
		title: "2’800",
		subtitle: "Reģistrētie darbinieki",
		order_lg: "order-lg-1 mt-lg-0",
		order_sm: "order-3 mt-5"
	},
	{
		title: "40",
		subtitle: "Uzņēmumi",
		order_lg: "order-lg-1",
		order_sm: "order-2"
	},
	{
		title: "500’000",
		subtitle: "Autorizāciju skaits sistēmā",
		order_lg: "order-lg-1 mt-lg-0",
		order_sm: "order-4 mt-5"
	}
];
const HomeFourthRow = () => {
	return (
		<div className="fourth-row d-flex flex-column justify-content-center align-items-center">
			<div className="container">
				<div className="row d-flex flex-row justify-content-between align-items-center">
					{data.map((_, index) => {
						const title = data[index]?.title;
						const subtitle = data[index]?.subtitle;
						const orderLg = data[index]?.order_lg;
						const orderSm = data[index]?.order_sm;
						return (
							<div className={`col-6 col-xl-3 d-flex flex-column ${orderSm} ${orderLg}`}>
								<StatsItem title={title} subtitle={subtitle} />
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default HomeFourthRow;
